import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Danke = () => {

return (
  <Layout>
    <Seo title="Danke" />
    <div className="container text-start my-5">
      <h1>Herzlichen Dank für Ihre Nachricht <i class="bi bi-envelope"></i></h1>

      <p>Wir werden Ihr Anliegen prüfen und uns bei Bedarf bei Ihnen melden.</p>

      <p class="mt-3">Ihr Budgettrips.de-Team</p>
           
    </div>
  </Layout>
)}

export default Danke
